@font-face {
  font-family: Panchang;
  src: url(./assets/fonts/Panchang-Medium.woff);
}

@font-face {
  font-family: PanchangSemi;
  src: url(./assets/fonts/Panchang-Semibold.woff);
}

@font-face {
  font-family: PanchangBold;    
  src: url(./assets/fonts/Panchang-Bold.woff);
}

@font-face {
  font-family: Chillax;
  src: url(./assets/fonts/Chillax-Medium.woff);
}

@font-face {
  font-family: ChillaxSemi;               
  src: url(./assets/fonts/Chillax-Semibold.woff);
}

@font-face {
  font-family: ChillaxBold;
  src: url(./assets/fonts/Chillax-Bold.woff);
}
@font-face {
  font-family: OpenSansMedium;
  src: url(./assets/fonts/OpenSans-Medium.ttf);
}
@font-face {
  font-family: ChillaxMedium;
  src: url(./assets/fonts/Chillax-Medium.woff);
}
@font-face {
  font-family: OpenSansBold;
  src: url(./assets/fonts/OpenSans-Bold.ttf);
}
@font-face {
  font-family: OpenSansSemiBold;
  src: url(./assets/fonts/OpenSans-SemiBold.ttf);
}
@font-face {
  font-family: OpenSansRegular;
  src: url(./assets/fonts/OpenSans-Regular.ttf);
}
@font-face {
  font-family: PoppinsSemiBold;
  src: url(./assets/fonts/Poppins-SemiBold.otf);
}
@font-face {
  font-family: PoppinsExtraBold;
  src: url(./assets/fonts/Poppins-ExtraBold.otf);
}
@font-face {
  font-family: PoppinsBold;
  src: url(./assets/fonts/Poppins-Bold.otf);
}
@font-face {
  font-family: PoppinsMedium;
  src: url(./assets/fonts/Poppins-Medium.otf);
}
/* .cursor {
  cursor: url("/assets/cursor/cursor.svg") 15 15, move !important;
} */

/* @supports ((-webkit-backdrop-filter: saturate(180%) blur(20px)) or (backdrop-filter: saturate(180%) blur(20px))) {
  html {
    --r-globalnav-segmentbar-background: #424245;
  }
} */

.blur::before {
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  width: contain;
  border-radius: 4px;
  padding: 0.3rem;
}
.blur {
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  width: contain;
  border-radius: 4px;
  padding: 0.3rem;
}

.title-red {
  background: linear-gradient(90.02deg, #c80e0e 18.25%, #ae1717 82.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.space {
  white-space: nowrap;
}

/* :focus-visible {
  display: none !important;
  outline: -webkit-focus-ring-color auto 1px;
}  */

.btn-bsc-icon-loading {
  -webkit-animation: btnSpin 2s infinite linear;
  -moz-animation: btnSpin 2s infinite linear;
  -o-animation: btnSpin 2s infinite linear;
  -ms-animation: btnSpin 2s infinite linear;
  animation: btnSpin 2s infinite linear;
}
@-webkit-keyframes btnSpin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes btnSpin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes btnSpin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes btnSpin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@keyframes btnSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.overFlow {
  overflow: hidden;
}